import Experience from 'src/screen/experience/experience-the-demo';
import Home from 'src/screen/home/home';
import SignIn from 'src/screen/sign-in/sign-in';
import About from 'src/screen/about/about';
import Upcoming from 'src/screen/upcoming-shows/upcoming';
import Event from 'src/components/event-details/event-details';
import EventDetails from 'src/components/event-details/event-details';
import Policy from 'src/components/policy/policy';
import SyncVideoAndAudio from 'src/screen/SyncVideoAndAudio/SyncVideoAndAudio';
import Player from 'src/components/video-player';
import OrderConfirmation from 'src/components/order-confirm/order-confirmation';

const RouteList = [
  {
    path: '/experience-the-demo',
    Component: Experience,
    defaultRoute: false,
  },
  {
    path: '/home',
    Component: Home,
    defaultRoute: true,
  },
  {
    path: '/about',
    Component: About,
    defaultRoute: false,
  },
  {
    path: '/sign-in',
    Component: SignIn,
    defaultRoute: false,
  },
  {
    path: '/upcoming-event',
    Component: Upcoming,
    defaultRoute: false,
  },
  {
    path: '/event-details/:id',
    Component: EventDetails,
    defaultRoute: false,
  },
  {
    path: '/terms-and-conditions/:id',
    Component: Policy,
    defaultRoute: false,
  },
  {
    path: '/terms-+-conditions/:id',
    Component: Policy,
    defaultRoute: false,
  },
  {
    path: '/faqs/:id',
    Component: Policy,
    defaultRoute: false,
  },
  {
    path: 'privacy/:id',
    Component: Policy,
    defaultRoute: false,
  },
  {
    path: 'order-confirmation/:id',
    Component: OrderConfirmation,
    defaultRoute: false,
  },
  // {
  //   path: '/video/:id',
  //   Component: Player,
  //   defaultRoute: false,
  // },
  {
    path: '/dmb-camden',
    Component: Player,
    defaultRoute: false,
  },
  {
    path: '*',
    Component: Home,
    defaultRoute: true,
  },
];
export default RouteList;
