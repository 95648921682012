import PubNub from 'pubnub';

export const GOOGLE_CLIENT_ID =
'943262573218-q1vku4jfka5pqnn8em2b0b2ojgfjg2kb.apps.googleusercontent.com';
export const FACEBOOK_CLIENT_ID = 'fc58ef5af30eb468ec9a48521c267f4d';
export const FACEBOOK_APP_ID = '1170649143949141';
export const GOOGLE_CLIENT_ID_Dummy =
  '7896671405-sces4k7g6n3tkpuauref2phfm5gmjofs.apps.googleusercontent.com';
export const FACEBOOK_CLIENT_ID_Dummy =
  '7896671405-sces4k7g6n3tkpuauref2phfm5gmjofs.apps.googleusercontent.com';

export const SSO_PROVIDER = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
};

export const LOGIN_SOURCE = {
  SOURCE_1: 1,
  SOURCE_2: 2,
  SOURCE_3: 3,
};

export const PAYMENT_GATEWAY_PUBLIC_KEY =
  'pk_live_51OuHmzP62IN2F3GHIfwn76hvjVUGPqM0H6O6kdTKprYjFMEylEiCC0CQNLKMVDnWAAXmev55DqsWrigs67N2bQX500tJj9eqIo';

export const PAYMENT_GATEWAY_PUBLIC_STAGE_KEY =
  'pk_test_51ONcsmSFVp49jNUUaQOBvzxA2Gru9AujQdzgAzn02e6dFEup1eqW8a9RyUQExSuP1L24MVIEQmF2HHXfmpTFd5NZ00L2Ae2QWB';

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: '27px',
      color: '#212529',
      fontSize: '1.1rem',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export const usePubnubProd = true;

export const PUBNUB_PUBLISH_KEY = usePubnubProd
  ? 'pub-c-61be60a0-f004-4c1d-8afd-58ba45281614'
  : 'pub-c-f286a5a9-e29f-4568-9aca-2b55f43442f8';
export const PUBNUB_SUBSCRIBE_KEY = usePubnubProd
  ? 'sub-c-15859c61-2a14-48e3-adbb-81b39c3e53e0'
  : 'sub-c-ba251fd8-289a-458b-b56e-e24d588b5499';

export const CHAT_PUBNUB_USER_ID = 'myUniqueUUID';
export const SIGNAL_PUBNUB_USER_ID = 'Volta_live';

export const pubnub_signal_config = new PubNub({
  publishKey: PUBNUB_PUBLISH_KEY,
  subscribeKey: PUBNUB_SUBSCRIBE_KEY,
  userId: SIGNAL_PUBNUB_USER_ID,
});
export const SHOW_TYPE = {
  LIVE: 'Live',
  VOD: 'VOD',
};
