
import { useEffect, useState } from "react";
import "./fotter.scss"
import decodeJwtToken from "src/utils/decode-jwt-token";
import { useFooterlinksMutation } from "src/redux/api/usersAPI";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const [footerlinks] = useFooterlinksMutation();
  const [footerLinks, setFooterLinks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getFooterLinks();
  }, []);

  const getFooterLinks = async () => {
    const response: any = await footerlinks({});
    const decodedToken = decodeJwtToken(response.data);
    const { data } = decodedToken?.data || {};
    setFooterLinks(data);
  }

  return (
    <>
    <div className="site-footer">
    
      <div className="sub-footer">
        <div className="sub-footer-first-section">
          {
            footerLinks?.map((item: any) => {
              const link = item?.label?.toLowerCase().replace(/\s+/g, '-').replace(/\s+&\s+/g, '-');
              return (
                <div key={item?.label} onClick={() => navigate(`${link}/${item.path}`)}>
                  <a>{item.label}</a>
                  
                </div>
              )
            })
          }
          <div className="support-footer"><a href="mailto:support@volta.live">support</a></div>
        </div>
        
        <div className="sub-footer-last">&#169;2024 VOLTA MEDIA GROUP LLC</div>
      </div>
      
      </div>
    </>
  );
};
export default Footer;
