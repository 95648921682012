import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import formatDate from "src/utils/format-date";
import "swiper/css";
import "./carousel.scss";
import { getAuthToken, getGuestUser } from "src/redux/service/auth";
import CheckoutContainer from "src/components/checkout-container/checkout-container";
import { useEffect, useState } from "react";
import { useTicketPurchaseValidityMutation } from "src/redux/api/usersAPI";
import decodeJwtToken from "src/utils/decode-jwt-token";
import Loader from "src/components/loader/loader";
import { SHOW_TYPE } from "src/config/config";

const Carousel = ({ sliderList, onCheckoutLoader }: any) => {
  const navigate = useNavigate();
  const isGuest = getGuestUser();
  const [ticketPurchaseValidity] = useTicketPurchaseValidityMutation();
  const [ticketPurchased, setTicketPurchased] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // if (isGuest !== "true") {
    //   checkUserTicketValidity();
    // }
  }, []);

  const getUserId = () => {
    const token: any = getAuthToken();
    const decodedToken: any = decodeJwtToken(token);
    const { data } = decodedToken;
    return data?.userId ? data.userId : '';
  };

  const checkUserTicketValidity = async () => {
    const response: any = await ticketPurchaseValidity({ userId: getUserId(), assetId: sliderList[0]?.path });
    const decodedToken = decodeJwtToken(response?.data);
    const { data } = decodedToken;
    setTicketPurchased(data.isActivePlan)
  };

  const handleJoinCrowd = (currentAssetDetails: any) => {
    const { airStartDate, contentType } = currentAssetDetails.asset[0] || {};
    const currentDateTime = new Date();
    const airStartDateRef = new Date(airStartDate);
    return (
      airStartDateRef &&
      currentDateTime < airStartDateRef &&
      contentType === SHOW_TYPE.LIVE &&
      ticketPurchased
    );
  };

  const handleLiveNow = (currentAssetDetails: any) => {
    const { airStartDate, contentType } = currentAssetDetails.asset[0] || {};
    const currentDateTime = new Date();
    const airStartDateRef = new Date(airStartDate);
    return (
      airStartDateRef &&
      currentDateTime >= airStartDateRef &&
      contentType === SHOW_TYPE.LIVE &&
      ticketPurchased
    );
  };

  const handleWatchNow = (currentAssetDetails: any) => {
    const { airStartDate, contentType } = currentAssetDetails.asset[0] || {};
    const currentDateTime = new Date();
    const airStartDateRef = new Date(airStartDate);
    return (
      ticketPurchased &&
      airStartDateRef &&
      currentDateTime > airStartDateRef &&
      contentType === SHOW_TYPE.VOD
    );
  };

  return (
    <div className="heroContainer">
      {
        !showLoader ?
          <Swiper
            cssMode={true}
            // autoplay={{
            //   delay: 4000,
            // }}
            // modules={[Autoplay]}
            // loop={true}
            className="heroSwiper"
          >
            {sliderList.map((item: any, index: number) => (
              <SwiperSlide key={index} className="heroSwiperSlide">
                <div className="imgCarousel ic-update">
                  <img className="swiper-img" src={item?.filePath} />
                  <div className="info-wrapper">
                    <div className="info-wrapper-main-heading">
                      {item?.asset[0]?.event_label}
                    </div>
                    <div className="info-title">{item?.asset[0]?.TitleLogoPath}</div>
                    <div className="info-heading">{item?.label}</div>
                    <div className="info-sub-heading">
                      {item?.asset[0]?.venue_description}
                    </div>
                    <div className="info-details">
                      {formatDate(item.asset[0]?.airStartDate)}
                    </div>
                    <div className="btn-wrapper">
                      {
                        isGuest === 'true' &&
                        <button className="btn-buy"
                          onClick={() => navigate(`/sign-in`)}
                        >Get Access</button>
                      }
                      
                      {isGuest !== "true" && item?.isButtonRequired == false && 
                        <button disabled className="btn-buy greyBtn" onClick={() => navigate(`/dmb-camden`)}>
                          Join The Crowd
                        </button>
                      }

                      {isGuest !== "true" && item?.isButtonRequired == true  && 
                        <button className="btn-buy" onClick={() => navigate(`/dmb-camden`)}>
                          Join The Crowd
                        </button>
                      }

                      {/* {!ticketPurchased && isGuest === "false" && (
                     <button className="btn-buy"
                     onClick={() => navigate(`/event-details/${item.path}`)}
                   >Buy Tiketes</button>
                      )} */}

                      {/* {isGuest !== "true" && handleJoinCrowd(item) && (
                        <button className="btn-buy" onClick={() => navigate(`/video/${item.path}`)}>
                          Join The Crowd
                        </button>
                      )} */}

                      {/* {isGuest !== "true" && handleLiveNow(item) && (
                        <button className="btn-buy" onClick={() => navigate(`/video/${item.path}`)}>
                          Live Now
                        </button>
                      )} */}

                      {/* {isGuest !== "true" && handleWatchNow(item) && (
                        <button className="btn-buy" onClick={() => navigate(`/video/${item.path}`)}>
                          Watch Now
                        </button>
                      )} */}

                      {/* <button
                        className="btn-info"
                        onClick={() => navigate(`/event-details/${item.path}`)}
                      >
                        more info 
                      </button> */}
                    </div>

                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          :
          <Loader />
      }
    </div>
  );
};
export default Carousel;
